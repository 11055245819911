import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { graphql } from "gatsby"
import React from "react"
import HubspotForm from "react-hubspot-form"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  title: string
  heading: string
  subheading: string
}

export const EnrolPageTemplate = (props: Props) => {
  return (
    <Container>
      <SEO
        title="Enrol"
        description="Fill out our enrolment form to register for a course's at The Institute of Beauty. All courses are ITEC and CIBTAC accredited"
      />
      <Grid
        container
        style={{
          marginTop: "60px",
          textAlign: "center",
          marginBottom: "40px",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <h1>Enrolment form</h1>
          <h2 style={{ fontWeight: 700 }}>
            Your journey to a new career starts here
          </h2>
          <h4>
            To register for a course at The Institue of Beauty, please fill out
            the below form. Once complete a member of our team will be in touch
            regarding next steps.
          </h4>
          <HubspotForm
            portalId="8706227"
            formId="0f4039db-de8b-4604-9e4b-250108ac3629"
          />
        </Grid>
      </Grid>
    </Container>
  )
}

interface EnrolPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        [key: string]: any
      }
    }
  }
}

const EnrolPage = ({ data }: EnrolPageProps) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <EnrolPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

export default EnrolPage

export const pageQuery = graphql`
  query EnrolPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "enrol-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
